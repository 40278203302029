<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo.png" />
    <div class="item">
      <div class="icon">
        <img class="iphoneIcon" src="@/assets/images/iphone_icon.png" />
      </div>
      <div class="inputBox">
        <span class="prefix">+86</span>
        <input
          type="number"
          v-model="phoneNumber"
          placeholder="请输入手机号码"
        />
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img class="codeIcon" src="@/assets/images/code_icon.png" />
      </div>
      <div class="codeBox">
        <input type="text" maxlength="6" v-model="code" placeholder="请输入验证码" />
        <span
          class="sendCode"
          v-if="!alreadySend"
          @click="sendCode"
          :style="{ color: phoneNumber ? '#FF6900' : '#CCCCCC' }"
          >{{isSendCode ? '重新获取' : '获取验证码'}}</span
        >
        <span class="sendCode" style="color: #FF6900" v-else>{{
          second + "S"
        }}</span>
      </div>
    </div>
    <div class="declare" @click="checkedDeclare">
      <img
        :src="
          isCheckedDeclare
            ? require('@/assets/images/system-complete.png')
            : require('@/assets/images/dis_system_complete_icon@2x.png')
        "
      />
      <span class="declareText">我已完整阅读并知晓<span class="highlight" @click="$router.push('/privacyPolicy')">《隐私声明》</span>全部内容，同意强生根据该《隐私声明》和《强生隐私政策》所述目的、范围和方式处理我提供的个人信息。</span>
    </div>
    <button class="login" @click="login">登录</button>
    <p class="goRegister">
      <router-link to="/register">没有账号？<span class="highlight">去注册</span></router-link>
    </p>
  </div>
</template>

<script>
import storage from "../../common/utils/storage";
import { mapState } from 'vuex'

export default {
  name: "Login",
  data() {
    return {
      phoneNumber: "", // 手机号数
      code: "", // 验证码
      isCheckedDeclare: false, // 是否同意用户协议
      second: 60,
      alreadySend: false, // 是否发送验证码
      isSendCode: false,
      validateNum: 0, // 验证验证码次数
    };
  },
  computed: {
    ...mapState(['Openid'])
  },
  methods: {
    // 发送验证码
    sendCode() {
      let errMsg = "";
      if (this.phoneNumber) {
        let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;
        if (!mobile_regex.test(this.phoneNumber)) {
          errMsg = "请输入11位手机号码！";
        }
      } else {
        errMsg = "请输入11位手机号码！";
      }

      if (errMsg == "") {
        this.timer();
        this.$axios.get('/Api/Api/Web/GetWxUser/GetVCode', {
          params: {
            Phone: this.phoneNumber,
            Type: 2
          }
        }).then((res) => {
          if (res.RetCode == '10000') {
            this.validateNum = 0;
          }
        })
      } else {
        this.$toast(errMsg);
      }
    },
    // 验证码倒计时
    timer() {
      this.isSendCode = true;
      this.alreadySend = true;
      let promise = new Promise((resolve) => {
        let setTimer = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.alreadySend = false;
            resolve(setTimer);
          }
        }, 1000);
      });
      promise.then((setTimer) => {
        clearInterval(setTimer);
      });
    },
    // 是否同意用户协议
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    // 登录
    login() {
      let errMsg = "";
      let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;

      if (this.isCheckedDeclare) {
        if (this.phoneNumber) {
          if (!mobile_regex.test(this.phoneNumber)) {
            errMsg = "手机号码格式不正确！";
          } else {
            if (this.code == "") {
              errMsg = "请输入短信验证码！";
            }
          }
        } else {
          errMsg = "请输入11位手机号码！";
        }
      } else {
        errMsg = "请勾选《隐私声明》并同意！";
      }

      if (errMsg == "") {
        if(this.validateNum < 5) {
          this.$axios.post('/Api/Api/Web/GetWxUser/Login', {
            OpenID: this.Openid,
            Phone: this.phoneNumber,
            Vcode: this.code
          }).then((res) => {
            if(res.RetCode == '-1') {
              if (res.RetMsg == '请用自己的微信登陆！') {
                this.$toast('请先去注册！');
              } else if(res.RetMsg == '验证码不正确！') {
                this.$toast(res.RetMsg);
                this.validateNum++;
              } else {
                this.$toast(res.RetMsg);
              }
            } else {
              if (res.Data.Token) {
                storage.setStore('Token', res.Data.Token);
                let redirectUrl = sessionStorage.getItem("redirectUrl");
                if(redirectUrl) {
                  this.$router.push(redirectUrl);
                  sessionStorage.removeItem('redirectUrl');
                } else {
                  this.$router.push('/');
                }
              }
            }
          })
        } else {
          this.$toast('请重新获取验证码！');
        }
      } else {
        this.$toast(errMsg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  padding: 12px;
  box-sizing: border-box;
}
.logo {
  width: 150px;
  display: block;
  margin: 50px auto;
}
.item {
  display: flex;
  height: 48px;
  line-height: 48px;
  border: 0.5px solid #ffffff;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #F3F5F5;
  .icon {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iphoneIcon {
      width: 22px;
    }
    .codeIcon {
      width: 20px;
    }
  }
  .icon::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #FFFFFF;
    position: absolute;
    top: 9px;
    right: 0px;
  }
  .inputBox {
    display: flex;
    flex: 1;
    .prefix {
      position: relative;
      padding: 0 8px 0 10px;
    }
    .prefix::after {
      content: "";
      width: 1px;
      height: 16px;
      background-color: #333333;
      position: absolute;
      top: 16px;
      right: 0px;
    }
    input {
      flex: 1;
      padding: 0 8px;
      border: none;
      box-sizing: border-box;
      background: transparent;
    }
  }
  .codeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 16px;
    input {
      width: 60%;
      padding: 0 6px;
      border: none;
      box-sizing: border-box;
      background: transparent;
    }
    .sendCode {
      font-size: 15px;
      display: inline-block;
      padding: 0 8px;
      color: #cccccc;
    }
  }
}
.declare {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  color: #666666;
  margin-top: 60px;
  img {
    width: 14px;
    height: 14px;
    margin: 2px 5px 0 10px;
  }
}
.highlight {
  color: $themeColor;
}
.login {
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  border: none;
  background-color: $themeColor;
  border-radius: 4px;
  margin: 15px 0 20px;
}
.goRegister {
  text-align: center;
  font-size: 13px;
  color: #666666;
  a {
    color: #666666;
  }
}
</style>
